import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Page from '../Page';
import clsx from '~src/helpers/clsx';
import {
	Button,
	Fab,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import RebootIcon from '@mui/icons-material/PowerSettingsNew';
import RemoveUserIcon from '@mui/icons-material/Delete';
import AddUserIcon from '@mui/icons-material/PersonAdd';
import OpenIcon from '@mui/icons-material/ZoomOutMap';
import KioskCloseIcon from '@mui/icons-material/Stop';
import KioskOpenIcon from '@mui/icons-material/PlayArrow';
import ScreenshotIcon from '@mui/icons-material/Camera';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Editor from '../Editor';
import useObservable from '~src/hooks/useObservable';
import DeviceConsole from '../DeviceConsole';
import { dbConsoleRunPath, dbDeviceCmdPath, dbDeviceCmdScriptPath, dbDeviceNamePath, dbObserveAny, dbDeviceConfigPlaylistPath, dbScreenHeightPath, dbScreenWidthPath, dbSet, dbUpdate, DeviceDoc, dbDeviceInvokePath } from '~src/services/db';
import { storageDelete, storageList } from '~src/services/storage';
import devices$ from '~src/messages/devices$';
import adminRole$ from '~src/messages/adminRole$';
import { getUserId } from '~src/services/functions';
import deviceId$ from '~src/messages/deviceId$';
import useDbVal from '~src/hooks/useDbVal';
import screenInfo$ from '~src/messages/screenInfo$';
import pageMode$, { PageMode } from '~src/messages/pageMode$';
import BridgeConsole from '../BridgeConsole';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		fontSize: '2em',
		margin: theme.spacing(1),
	},
	paper: {
		width: 400,
		margin: theme.spacing(1),
		overflow: 'auto',
	},
	deviceList: {
		display: 'flex',
		flexWrap: 'wrap',
		overflowY: 'auto',
	},
	deviceItem: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		width: 400,
		height: 300,
		background: '#ff000030',
		boxShadow: theme.shadows['2'],
		borderRadius: 5,
		boxSizing: 'border-box',
		overflow: 'hidden',
		'& textarea': {
			width: '100%',
		},
	},
	deviceItemConnected: {
		background: '#f3f3f3',
	},
	deviceItemHeader: {
		color: 'white',
		padding: theme.spacing(1),
		fontSize: '140%',
		textAlign: 'center',
		// background: theme.palette.secondary.main,
	},
	deviceItemScreen: {
		flex: 1,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		display: 'flex',
		padding: theme.spacing(0.5),
		'& .MuiFab-root': {
			margin: theme.spacing(0.5),
		},
	},
	device: {
		flex: 2,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	deviceScreen: {
		background: 'black',
		margin: theme.spacing(1),
		marginRight: 0,
		borderRadius: 5,
		overflow: 'hidden',
		width: 800,
		height: 585,
		position: 'relative',
		'& div': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			overflow: 'auto',
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'& img': {
			margin: 5,
			maxWidth: 800,
			maxHeight: 585,
		},
	},
	deviceInfo: {
		flex: 1,
		margin: theme.spacing(1),
		border: '1px solid grey',
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	deviceUsers: {
		flex: 1,
		margin: theme.spacing(1),
		border: '1px solid grey',
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
	deviceConsole: {
		flex: 1,
		margin: 0,
		padding: theme.spacing(1),
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		background: 'black',
		color: '#85ff85',
		overflowY: 'auto',
	},
	deviceConsoleField: {
		display: 'flex',
		flexDirection: 'row',
		'& input': {
			flex: 1,
			margin: theme.spacing(0.5),
			fontSize: '110%',
		},
		'& button': {
			margin: theme.spacing(0.5),
		},
	},
	deviceConsoleError: {
		color: 'red',
	},
	deviceConsoleButtons: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'& .MuiButton-root': {
			margin: theme.spacing(0.5),
		},
	},
	usersView: {
		'& .list': {},
		'& .item': {},
	},
}));

const deviceInvoke = (deviceId: string, method: string, ...args: any[]) => {
	const invokePath = dbDeviceInvokePath(deviceId);
	dbSet(invokePath, { method, args });
}

async function refreshDevice(deviceId: string) {
	console.log('refreshDevice', deviceId);

	const rmDir = (dirPath: string) => {
		return storageList(dirPath).then(({ items }) => {
			items.forEach(async (item) => {
				try {
					const res = await storageDelete(item).catch(() => storageDelete(item));
					console.debug('delete file OK', item.toString(), res);
				} catch (err) {
					console.error('delete file KO', item.toString(), err);
				}
			});
		});
	};

	await rmDir(deviceId);
	await rmDir('screens/' + deviceId);

	await dbSet(dbDeviceCmdScriptPath(deviceId), 'screen capture');
}

function UsersView() {
	const classes = useStyles();
	const deviceId = useObservable(deviceId$);
	const [email, setEmail] = useState('');
	const users$ = useMemo(
		() => dbObserveAny<{ [userId: string]: { email: string; role: 'rwa' } }>(`deviceUsers/${deviceId}`),
		[deviceId],
	);
	const users = useObservable(users$) || {};

	console.debug('UsersView users', deviceId, users);

	const handleAdd = async () => {
		console.debug('handleAdd', email);
		const userId = await getUserId(email);
		console.debug('handleAdd userId', userId);
		if (typeof userId === 'string' && userId.length > 5) {
			dbSet(`roles/${userId}/${deviceId}`, 'rwa');
		}
	};

	const getHandleDelete = (userId: string) => () => {
		console.debug('getHandleDelete', userId);
		dbSet(`roles/${userId}/${deviceId}`, null);
	};

	return (
		<div className={classes.usersView}>
			<List>
				{Object.entries(users).map(([userId, { email }]) => (
					<ListItem key={userId}>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText primary={email} />
						<ListItemSecondaryAction>
							<IconButton edge="end" onClick={getHandleDelete(userId)} size="large">
								<RemoveUserIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
				<ListItem>
					<ListItemIcon>
						<AccountCircleIcon />
					</ListItemIcon>
					<InputBase placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
					<ListItemSecondaryAction>
						<IconButton edge="end" color="primary" onClick={handleAdd} size="large">
							<AddUserIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		</div>
	);
}

function DeviceSelected({ device, isAdmin }: { device: DeviceDoc; isAdmin: boolean }) {
	console.debug('DeviceSelected', device, isAdmin);
	const classes = useStyles();
	const [cmd, setCmd] = useState(device.cmd?.last);

	const send = (cmd?: string) => {
		if (!cmd) return;
		dbUpdate(dbDeviceCmdPath(device.id), { script: cmd, timeout: 240000 });
	};

	const handleSend = () => {
		if (!device.id) return;
		console.debug('handleSend', cmd);
		send(cmd);
	};

	const getHandleSend = (cmd: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!device.id) return;
		setCmd(cmd);
		console.debug('handleSend', cmd);
		send(cmd);
	};

	const commands = [
		{ cmd: 'screen capture', title: 'Capture' },
		{ cmd: 'kiosk close', title: 'Fermer le kiosk' },
		{ cmd: 'kiosk open', title: 'Ouvrir le kiosk' },
		{ cmd: 'kiosk reload', title: 'Redémarrer le kiosk' },
		{ cmd: 'nircmd sendkeypress F5', title: 'Rafraichir' },
		{ cmd: 'service update', title: 'Mettre à jour' },
		{ cmd: 'service restart', title: 'Redémarrer le service' },
		{ cmd: 'reboot', title: "Redémarrer l'ordinateur" },
		{ cmd: 'ver', title: 'Windows Version' },
	];

	const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
		console.debug('handleMouseEnter', e.target);
		// const w = window as any;
		// if (w._handleKey) {
		// 	document.removeEventListener('keydown', w._handleKey);
		// 	delete w._handleKey;
		// }
		// w._keys = [];
		// w._handleKey = (e: KeyboardEvent) => {
		// 	console.debug('handleKey', e);
		// 	const keyMap = {
		// 		' ': 'spc',
		// 		'+': 'plus',
		// 		'-': 'minus',
		// 		'/': 'divide',
		// 		'*': 'multiply',
		// 		'backspace': 'backspace',
		// 		'escape': 'esc',
		// 		'tab': 'tab',
		// 		'arrowup': 'up',
		// 		'arrowdown': 'down',
		// 		'arrowleft': 'left',
		// 		'arrowright': 'right',
		// 		'\'': 'comma',
		// 		'pause': 'pause',
		// 		'meta': 'lwin',
		// 	};
		// 	let key = e.key.toLowerCase();
		// 	key = keyMap[key] || key;
		// 	if (e.altKey) key = `alt+${key}`;
		// 	if (e.ctrlKey) key = `ctrl+${key}`;
		// 	if (e.shiftKey) key = `shift+${key}`;
		// 	console.debug('handleKey key', key);
		// 	w._keys.push(`${key}`);
		// 	clearTimeout(w._keysTimeout);
		// 	w._keysTimeout = setTimeout(() => {
		// 		const keys = w._keys.join(' ');
		// 		console.debug('handleKey keys', keys);
		// 		w._keys = [];
		// 		send(`eval: await nircmd('sendkeypress ${keys}'); await screenshot(); await sleep(1000); await screenshot();`);
		// 	}, 500);
		// };
		// document.addEventListener('keydown', w._handleKey);
	};

	const handleMouseLeave = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
		console.debug('handleMouseLeave', e.target);
		// const w = window as any;
		// if (w._handleKeyPress) {
		// 	document.removeEventListener('keypress', w._handleKeyPress);
		// 	delete w._handleKeyPress;
		// }
	};

	const handleMouseClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
		const img = e.target as HTMLImageElement;
		const rect = img.getBoundingClientRect();
		const x = Math.round(((e.clientX - rect.x) / rect.width) * (device.screenWidth || 0));
		const y = Math.round(((e.clientY - rect.y) / rect.height) * (device.screenHeight || 0));
		console.debug('handleMouseClick', x, y);
		send(
			`eval: await nircmd("setcursor ${x} ${y}"); await nircmd("sendmouse left click"); await screenshot(); await sleep(1000); await screenshot();`,
		);
	};

	return (
		<div className={classes.device}>
			<div className={classes.deviceScreen}>
				<div>
					{device.screenshotUrl ? (
						<img
							alt="screenshot error"
							src={device.screenshotUrl}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							onClick={handleMouseClick}
						/>
					) : (
						<span>no screenshot</span>
					)}
				</div>
			</div>
			<div className={classes.deviceInfo}>
				{device.bridge && isAdmin && (
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Bridge Console</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<BridgeConsole />
						</AccordionDetails>
					</Accordion>
				)}
				{!device.bridge && isAdmin && (
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Console 0</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ flexDirection: 'column' }}>
							{device.cmd && (
								<div className={classes.deviceConsole}>
									<span>{`isConnected = ${device.isConnected} || connected = ${device.connected} || disconnected = ${device.disconnected}`}</span>
									<span>{`${device.id} -> ${device.cmd?.last} -> ${device.cmd?.success} (${device.cmd?.exitCode})`}</span>
									{device.cmd?.data?.map((line, i) => (
										<span key={i}>{line}</span>
									))}
									{device.cmd?.error?.map((line, i) => (
										<span key={i} className={classes.deviceConsoleError}>
											{line}
										</span>
									))}
								</div>
							)}
							<div className={classes.deviceConsoleField}>
								<input value={cmd} onChange={(e) => setCmd(e.target.value)} />
								<button onClick={handleSend}>Envoyer</button>
							</div>
							<div className={classes.deviceConsoleButtons}>
								{commands.map((command) => (
									<Button
										key={command.cmd}
										size="small"
										variant="contained"
										color="primary"
										onClick={getHandleSend(command.cmd)}
									>
										{command.title}
									</Button>
								))}
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={() => {
										send(
											`eval: await nircmd("sendkeypress ${cmd}"); return screenshot(); await sleep(1000); return screenshot();`,
										);
									}}
								>
									Envoyer touche
								</Button>
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={() => {
										dbSet(dbDeviceNamePath(device.id), cmd);
									}}
								>
									Modifier Nom
								</Button>
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={() => {
										(window as any)._playlist = device.config.playlist;
									}}
								>
									Copier Playlist
								</Button>
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={() => {
										dbSet(dbDeviceConfigPlaylistPath(device.id), (window as any)._playlist);
									}}
								>
									Coller Playlist
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>
				)}
				{!device.bridge && isAdmin && (
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Console</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<DeviceConsole />
						</AccordionDetails>
					</Accordion>
				)}
				<Accordion defaultExpanded={!isAdmin}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Liste des utilisateurs</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<UsersView />
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
}

function DeviceItem({
	device,
	onOpenClick,
	onEditClick,
}: {
	device: DeviceDoc;
	onOpenClick: (id: string) => void;
	onEditClick: (id: string) => void;
}) {
	const classes = useStyles();
	const [isKioskOpen, setIsKioskOpen] = useState(true);
	const isConnected =
		device.isConnected && (device.disconnected === undefined || device.disconnected < device.connected);
	const deviceId = deviceId$.value;

	const handleOpen = () => {
		onOpenClick(device.id);
	};

	const handleRefresh = () => {
		refreshDevice(device.id);
	};

	const handleEdit = () => {
		onEditClick(device.id);
	};

	const sendExpr = (expr: string) => {
		console.debug('sendExpr', expr);
		dbSet(dbConsoleRunPath(device.id), { expr, type: 'js', timeout: 240000 });
	};

	const handleReboot = () => {
		console.debug('handleReboot');
		sendExpr(`api.nircmd('exitwin reboot')`);
	};

	const handleKioskOpen = () => {
		console.debug('handleKioskOpen');
		setIsKioskOpen(true);
		sendExpr(`api.kioskReload()`);
	};

	const handleKioskClose = () => {
		console.debug('handleKioskClose');
		setIsKioskOpen(false);
		sendExpr(`api.kioskClose()`);
	};

	return (
		<div className={clsx(classes.deviceItem, isConnected && classes.deviceItemConnected)}>
			<div className={classes.deviceItemHeader} onClick={handleOpen}>
				{device.name} ({device.version})
			</div>
			<div
				className={classes.deviceItemScreen}
				style={{
					backgroundImage: isConnected ? `url(${device.screenshotUrl})` : 'none',
				}}
			>
				{device.bridge ? (
					<>
						{/* <Fab size="small" color="error" onClick={() => deviceInvoke(deviceId, 'toggleKiosk')}>
							{device.kiosk ? <KioskOpenIcon /> : <KioskCloseIcon />}
						</Fab> */}
						<Fab size="small" color="default" onClick={() => deviceInvoke(deviceId, 'takeScreenshot')}>
							<ScreenshotIcon />
						</Fab>
						<Fab size="small" color="default" onClick={() => deviceInvoke(deviceId, 'reload')}>
							<RefreshIcon />
						</Fab>
						<Fab size="small" color="secondary" onClick={handleOpen}>
							<OpenIcon />
						</Fab>
						<Fab size="small" color="primary" onClick={handleEdit}>
							<EditIcon />
						</Fab>
					</>
				) : (
					<>
						{/* <Fab size="small" color="default" onClick={handleReload}>
							<ReloadIcon />
						</Fab> */}
						<Fab size="small" color="default" onClick={handleReboot}>
							<RebootIcon />
						</Fab>
						{isKioskOpen ? (
							<Fab size="small" color="default" onClick={handleKioskClose}>
								<KioskCloseIcon />
							</Fab>
						) : (
							<Fab size="small" color="default" onClick={handleKioskOpen}>
								<KioskOpenIcon />
							</Fab>
						)}
						<Fab size="small" color="default" onClick={handleRefresh}>
							<RefreshIcon />
						</Fab>
						<Fab size="small" color="secondary" onClick={handleOpen}>
							<OpenIcon />
						</Fab>
						<Fab size="small" color="primary" onClick={handleEdit}>
							<EditIcon />
						</Fab>
					</>
				)}
			</div>
		</div>
	);
}

export default function NewAdminPage() {
	const classes = useStyles();

	const adminRole = useObservable(adminRole$) || { isAdmin: false };
	const devices = useObservable(devices$) || [];
	const deviceId = useObservable(deviceId$);
	const pageMode = useObservable(pageMode$);

	const isAdmin = adminRole.isAdmin;

	console.debug('devices', devices);

	const open = (pageMode === PageMode.Open && devices.find((d) => d.id === deviceId)) || null;
	const edit = (pageMode === PageMode.Edit && devices.find((d) => d.id === deviceId)) || null;

	const handleOpenClick = (id: string) => {
		deviceId$.next(id);
		pageMode$.next(PageMode.Open);
	};

	const handleEditClick = (id: string) => {
		deviceId$.next(id);
		pageMode$.next(PageMode.Edit);
	};

	const handleAllRefresh = () => {
		devices.forEach((device) => device.bridge ? deviceInvoke(device.id, 'takeScreenshot') : refreshDevice(device.id));
	};

	const screenWidth = useDbVal(dbScreenWidthPath(deviceId), 1920);
	const screenHeight = useDbVal(dbScreenHeightPath(deviceId), 1080);

	useEffect(() => {
		screenInfo$.next({
			width: screenWidth,
			height: screenHeight,
		})
	}, [screenHeight, screenWidth]);

	return edit ? (
		<Page className={classes.root} title={edit.name || ''}>
			<Editor />
		</Page>
	) : (
		<Page className={classes.root} title="Administration (v1.3)">
			{open && <DeviceSelected key={open.id} device={open} isAdmin={isAdmin} />}
			<div className={classes.deviceList}>
				{devices.map((device) => (
					<DeviceItem key={device.id} device={device} onOpenClick={handleOpenClick} onEditClick={handleEditClick} />
				))}
			</div>
			<Button size="small" variant="contained" color="primary" onClick={handleAllRefresh} startIcon={<RefreshIcon />}>
				Tout Actualiser
			</Button>
		</Page>
	);
}
